import api, {create, deleteOne, getAll, getList, getOne, RequestError, RequestResponse, update} from '../api';
import {Clientes, QueryParams, Empresa} from '../../types';
const endpoint = 'empresas';
export default  {
    all: async (params?: QueryParams) => getAll<Empresa>({ endpoint, params }),
    one: async (id: string) => getOne<Empresa>({ endpoint, id }),
    getList: async () => getList<Clientes>({ endpoint }),
    destroy: async (id: string) => deleteOne({ endpoint, id }),
    create: async (formData: Partial<Empresa>) => create<Empresa>({ endpoint, formData }),
    update: async (id: string, formData: Partial<Empresa>) => update<Empresa>({ endpoint, formData, id }),

}