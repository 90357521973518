import Form from "../../../components/form/Form";
import Title from "../../../components/text/Title";
import Input from "../../../components/input/Input";
import ColoredButton from "../../../components/button/ColoredButton";
import React, {useState} from "react";
import useForm from "../../../hooks/useForm";
import {ForgotPassData} from "../../../types";
import logoSuplen from "../../../assets/images/logo-suplen.png";
import logoTopMed from "../../../assets/images/ImageToStl.com_logo+topmed.png";

export default function RecuperarSenha() {
    const [errorMessage, setErrorMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const { form, isFormInvalid, handleChange } = useForm<ForgotPassData>({
        validator: (data) => !data.email
    });

    async function handleSubmit() {
        setErrorMessage('');
        setIsLoading(true);
    }

    return (
        <div className={'bg-gradient-to-r from-cyan-500 to-blue-500'}>
            <div className="flex items-center justify-center h-screen p-5">
                <div className="lg:shadow w-full w-max">
                    <Form
                        className="bg-slate-50 shadow lg:shadow-none p-9 grid place-items-center mx-auto lg:w-[26rem] rounded-md"
                        onSubmit={() => {handleSubmit()}}
                    >
                        <div className={'flex gap-4'}>
                            <img src={logoSuplen} className="max-w-[125px]" />
                            <img src={logoTopMed} className="max-w-[125px]" />
                        </div>

                        <Title size="2xl" color="primary" className="mb-2 mt-8">Recuperação de senha</Title>
                        <p className="text-2xl  mb-5 text-primary font-bold">Portal Admin</p>
                        <p className="text-md text-secondary">Informe seu e-mail.</p>

                        {!!errorMessage?.length && <p className="mt-5 text-red-600">{errorMessage}</p>}

                        <Input
                            large
                            hideLabel
                            inputType="email"
                            className="my-5"
                            value={form?.email}
                            setValue={(v) => handleChange(v, 'email')}
                        />
                        <ColoredButton
                            className="mt-6 mb-4"
                            disabled={isFormInvalid}
                            loading={isLoading}
                            type="submit"
                        >
                            Recuperar senha
                        </ColoredButton>

                        <a className="text-primary hover:text-primary-700 transition cursor-pointer" href={'/admin/entrar'}>
                            Voltar
                        </a>
                    </Form>
                </div>
            </div>
        </div>
    );
}