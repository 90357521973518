import React, { useEffect } from 'react';

import Str from '../../../helpers/Str';

import AsideMenu from '../../../components/nav/AsideMenu';
import Navbar from '../../../components/nav/Navbar';

export interface PageContainerProps {
    children: React.ReactNode;
    className?: string;
    menuTitle?: string;
    pageTitle?: string;
    innerClassName?: string;
}

export default function PageContainer({
  children,
  className,
  innerClassName,
  menuTitle,
  pageTitle,
  ...rest
}: PageContainerProps) {
    useEffect(() => {
        document.title = pageTitle || menuTitle || 'Inovatta Solutions';
    }, []);


    return (
        <div {...rest} className={Str.tw(
            className,
        )}>
            <Navbar pageTitle={pageTitle || menuTitle} />
            <AsideMenu menuTitle={menuTitle} />

            <main className={Str.tw(innerClassName, 'grid gap-2 col-span-10 p-2 pl-[206px]')}>
                {children}
            </main>
        </div>
    );
}
