import React from 'react';

import SearchSrc from '../../../assets/images/search.png';
import Str from '../../../helpers/Str';

export interface SearchButtonProps {
  className?: string;
  type?: 'button' | 'submit';
  onClick?: any;
}

export default function SearchButton({
  className,
  type = 'submit',
  onClick,
}: SearchButtonProps) {
  return (
    <button
      type={type} 
      className={Str.tw(
        className,
        'w-[39px] h-[39px] hover:bg-light hover:border p-2 transition rounded-md'
      )}
      onClick={e => onClick()}
    >
      <img src={SearchSrc} />
    </button>
  );
}
