import React from 'react';
import Str from '../../../helpers/Str';

export interface Button {
  className?: string;
  children?: React.ReactNode;
  type?: 'button' | 'submit';
  disabled?: boolean;
  loading?: boolean;
  onClick?: () => void;
}

export default function Button({
  className,
  children,
  type = 'button',
  loading,
  disabled,
  ...rest
}: Button) {
  const finalDisabled = disabled || loading;

  return (
    <button {...rest} disabled={finalDisabled} type={type} className={Str.tw(
      'bg-light border rounded-md w-max min-w-[117px] p-3',
      'transition hover:bg-primary hover:text-white active:border-primary',
      'disabled:opacity-70 disabled:pointer-events-none',
      'font-bold ',
      className,
    )}>
      {loading ? (
        <span className="flex items-center justify-center h-7">
          <svg className="animate-spin -ml-1 mr-3 h-[14px] w-[14px] text-black" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
          </svg>
        </span>
      ) : (
        <span>{children}</span>
      )}
    </button>
  );
}
