import api, { RequestError, RequestResponse } from '../api';
import {ForgotPassData, LoginAdminData, Usuario} from '../../types';

export default  {
    async login(loginData: LoginAdminData): Promise<RequestError | RequestResponse<{
        status: true;
        message: string;
        token: string;
        usuario: Usuario;
        menu: any;
    }>> {
        try {
            const { data } = await api.post('/auth/login', loginData);
            return { data, isError: false };
        } catch (err: any) {
            return {
                data: err?.response?.data ?? { message: 'Falha ao processar requisição' },
                isError: true
            };
        }
    },
}