import {useNavigate, useParams} from "react-router-dom";
import PageContainer from "../../../../../components/container/PageContainer";
import React, {useEffect, useState} from "react";
import Title from "../../../../../components/text/Title";
import IconButton from "../../../../../components/button/IconButton";
import {BiArrowBack} from "react-icons/bi";
import Button from "../../../../../components/button/Button";
import PageCard from "../../../../../components/card/PageCard";
import {Empresa} from "../../../../../types";
import usuariosAdminApi from "../../../../../services/adminServices/usuariosAdminApi";
import Alert from "../../../../../helpers/Alert";
import empresasApi from "../../../../../services/adminServices/empresasApi";
import clientesApi from "../../../../../services/adminServices/clientesApi";
import Input from "../../../../../components/input/Input";
export default function FormularioEmpresas() {
    const params = useParams();
    const id = params.id;
    const navigate = useNavigate();
    const [load, setLoad] = useState(false);
    const [state, setState] = useState<Empresa>({
        id: '',
        nome: '',
        codigo: '',
        loja: '',
        cnpj: '',
        filial_protheus: '',
        logo: '',
    })
    useEffect(() => {
        loadData();
    }, []);
    async function loadData() {
        if (id){
            setLoad(true);
            const {data, isError} = await empresasApi.one(id);
            if (!isError){

                const newState = {
                    id: data.id,
                    nome: data.nome,
                    codigo: data.codigo,
                    loja: data.loja,
                    cnpj: data.cnpj,
                    filial_protheus: data.filial_protheus,
                    logo: data.logo,
                };
                setState(newState);
            }
        }
        setLoad(false);
    }
    const handleSubmit = async () => {
        setLoad(true);
        const {data, isError} = id ? await empresasApi.update(id, state) : await empresasApi.create(state);
        setLoad(false);
        if (isError){
            if (typeof data === 'object') {
                Object.keys(data).forEach(function(key, index) {
                    Alert.error(data[key][0]);
                });
                return;
            }

            return Alert.error(data.message? data.message : data);
        }
        Alert.success('Cliente salvo com sucesso');
        navigate('/admin/empresa');

    }
    async function handleDelete() {
        console.log('aki');
    }
    const handleChange = (value: any, input: string) => {
        setState({...state, [input]: value});
    };
    return(
        <PageContainer menuTitle="Cadastro de empresas">
            <PageCard
                header={
                    <div className="flex items-center justify-between">
                        <Title color="primary">Cadastro de Empresa</Title>
                        <IconButton title="Voltar" icon={BiArrowBack} onClick={() => navigate('/admin/empresa')} />
                    </div>
                }
                footer={
                    <div className="flex items-center gap-5">
                        <Button loading={load} onClick={handleSubmit}>
                            Salvar
                        </Button>
                        {state.id && <Button loading={load} onClick={handleDelete}>Excluir</Button>}
                        {/*{state.id && <Button loading={load} onClick={() => {window.location.href = '/usuarios/cadastro';}}>Novo</Button>}*/}
                    </div>
                }
            >
                {
                    load ?
                        <span className="flex items-center justify-center h-7">
                          <svg className="animate-spin -ml-1 mr-3 h-[14px] w-[14px] text-black" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                          </svg>
                        </span>
                        :
                        <>
                            <div className="grid grid-cols-3 gap-5 pb-7">
                                <Input
                                    label="Nome"
                                    className="sm:col-span-1"
                                    value={state.nome}
                                    setValue={v => handleChange(v, 'nome')}
                                />
                                <Input
                                    label="Código"
                                    className="sm:col-span-1"
                                    value={state.codigo}
                                    setValue={v => handleChange(v, 'codigo')}
                                />
                                <Input
                                    label="Loja"
                                    className="sm:col-span-1"
                                    value={state.loja}
                                    setValue={v => handleChange(v, 'loja')}
                                />
                            </div>
                            <div className="grid grid-cols-3 gap-5 pb-7">
                                <Input
                                    label="CNPJ"
                                    className="sm:col-span-1"
                                    inputType={'CNPJ'}
                                    value={state.cnpj}
                                    setValue={v => handleChange(v, 'cnpj')}
                                />
                                <Input
                                    label="Filial protheus"
                                    className="sm:col-span-1"
                                    value={state.filial_protheus}
                                    setValue={v => handleChange(v, 'filial_protheus')}
                                />
                                <Input
                                    label="Logo"
                                    className="sm:col-span-1"
                                    value={state.logo}
                                    setValue={v => handleChange(v, 'logo')}
                                />
                            </div>
                        </>
                }
            </PageCard>
        </PageContainer>
    )
}