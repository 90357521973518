import PageContainer from "../../../../components/container/PageContainer";
import ListPageLayout from "../../../../components/layout/ListPageLayout";
import empresasApi from "../../../../services/adminServices/empresasApi";

export default function Empresas() {
    return <ListPageLayout
        menuTitle="Cadastro de empresas"
        searchPlaceholder="Buscar empresa"
        creationEndpoint="/admin/empresa/cadastro"
        creationLabel="Cadastrar empresa"
        loader={empresasApi.all}
        columns={[
            { title: 'ID', field: 'id'},
            { title: 'Nome', field: 'nome'},
            { title: 'Código', field: 'codigo'},
            { title: 'loja', field: 'loja'},
            { title: 'Filial protheus', field: 'filial_protheus'},
        ]}
    />;
}