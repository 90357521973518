import api, {create, deleteOne, getAll, getOne, listAll, getList, RequestResponse, update} from '../api';
import {QueryParams, Clientes} from '../../types';
const endpoint = 'clientes';
const allConsult= 'clientes/all';

export default  {
    all: async (params?: QueryParams) => getAll<Clientes>({ endpoint, params }),
    listAll: async () => listAll<Clientes>({ endpoint, allConsult}),
    getList: async () => getList<Clientes>({ endpoint }),
    one: async (id: string) => getOne<Clientes>({ endpoint, id }),
    destroy: async (id: string) => deleteOne({ endpoint, id }),
    create: async (formData: Partial<Clientes>) => create<Clientes>({ endpoint, formData }),
    update: async (id: string, formData: Partial<Clientes>) => update<Clientes>({ endpoint, formData, id }),
    async getClientsFilial(search: any){
        try {
            const url = `${allConsult}/${search}`;
            const { data } = await api.get(url)
            return { data, isError: false };
        } catch (err: any) {
            return {
                data: err?.response?.data ?? { message: 'Falha ao processar requisição' },
                isError: true
            };
        }
    },
}