import PageContainer from "../../../../components/container/PageContainer";
import React, {useEffect, useState} from "react";
import useTable from "../../../../hooks/useTable";
import leiturasApi from "../../../../services/adminServices/leiturasApi";
import IconButton from "../../../../components/button/IconButton";
import {BiEdit} from "react-icons/bi";
import Table from "../../../../components/table/Table";
import BaseTable from "../../../../components/table/BaseTable";
import { FaDownload } from "react-icons/fa";
import Input from "../../../../components/input/Input";
import SearchButton from "../../../../components/button/SearchButton";
import Alert from "../../../../helpers/Alert";
import Dates from "../../../../helpers/Dates";
export default function LeiturasApontamento() {
    const [dataTable, setDataTable] = useState([]);
    const [loadTable, setLoadTable] = useState(false);
    const [search, setSearch] = useState({
        dataInicio: '',
        dataFim: '',
    });

    async function getLeituras(){
        if (!search.dataInicio || !search.dataFim){
            Alert.error("Data início e fim devem ser preenchidas!");
            return;
        }
        setLoadTable(true);
        const { data, isError } = await leiturasApi.getLeiturasApontamento(search);
        if (!isError){
            setDataTable(data);
        }
        setLoadTable(false);
    }

    const columns: any = [
        { title: 'Paciente', field: 'paciente' },
        { title: 'Usuário', field: 'usuario' },
        { title: 'Data', field: 'data' },
    ];
    function leituraDownload(path: any){
        let link = document.createElement("a");
        link.href = path;
        link.download = "Leitura.xlsx";
        link.click();
    }
    const handleChange = (value: any, input: string) => {
        setSearch({...search, [input]: value});
    };
    return(
        <PageContainer menuTitle="Leituras de apontamento">
            <div className="grid grid-cols-12 gap-4 py-5">
                <Input
                    label="Data Inicio"
                    className="sm:col-span-3"
                    type={"date"}
                    value={search.dataInicio}
                    setValue={v => handleChange(v, 'dataInicio')}
                />
                <Input
                    label="Data Fim"
                    className="sm:col-span-3"
                    type={"date"}
                    value={search.dataFim}
                    setValue={v => handleChange(v, 'dataFim')}
                />
                <SearchButton className={'sm:col-span-3 ml-2 mt-[1.6rem]'} onClick={getLeituras} />
            </div>
            {
                loadTable ? (
                        <span className="flex items-center justify-center h-7">
                            <svg className="animate-spin -ml-1 mr-3 h-[14px] w-[14px] text-black" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                        </span>
                    )
                    :
                    (
                        <>
                            <table className="w-full">
                                <thead className="bg-light border-b">
                                <tr className="py-3 px-5">
                                    {
                                        columns.map((coll: any, i: number) => <th key={i} className="text-start py-3 px-5">{coll.title}</th>)
                                    }
                                    <th className="text-start py-3 px-5">

                                    </th>
                                </tr>

                                </thead>
                                <tbody>
                                {
                                    dataTable ? dataTable.map((dataT: any, index: number) => {
                                            return (
                                                <tr className={'border-t'}>
                                                    {
                                                        columns && columns.map((column: any) => {
                                                            if (column.field === 'data') {
                                                                return(
                                                                    <td className={''}>
                                                                        <span className={'ml-5'}>{Dates.convertEnToBr(dataT[column.field], 'T')}</span>
                                                                    </td>
                                                                )
                                                            }
                                                            return(
                                                                <td className={''}>
                                                                    <span className={'ml-5'}>{dataT[column.field]}</span>
                                                                </td>
                                                            )
                                                        })
                                                    }
                                                    <td className={''}>
                                                        <span className={'ml-5'}><IconButton icon={FaDownload} onClick={() => leituraDownload(dataT.path)} /></span>
                                                    </td>
                                                </tr>
                                            )
                                        }) :
                                        ''
                                }
                                </tbody>
                            </table>
                        </>
                    )
            }

        </PageContainer>
    )
}