import React, {useState, createElement, useEffect} from 'react';
import { FiChevronDown } from 'react-icons/fi';

import menus, { Menu } from '../../../router/menus';
import Str from '../../../helpers/Str';
import { IconBaseProps } from 'react-icons/lib';
import { useNavigate } from 'react-router';
//import menuApi from "../../../services/menuApi";
import {FaClipboardCheck, FaUser} from "react-icons/fa";
import {MdDashboard} from "react-icons/md";
import useAppContext from "../../../hooks/useAppContext";
import Storage from "../../../helpers/Storage";

export interface AsideMenuProps {
  menuTitle?: string;
}

export default function AsideMenu({
  menuTitle
}: AsideMenuProps) {
    const [loading, setLoading] = useState(false);
    /*const appContext = useAppContext();
    const menusData = Storage.getUserMenu();
    const [menus, setMenus] = useState([]);

    useEffect(() => {
        setLoading(true);
        if (menusData) {
            appContext.setMenus(menusData);
            //setMenus(appContext.menus);
        }
        setLoading(false);
    },[]);*/
   
    return loading ? (
        <span className="flex items-center justify-center h-7">
          <svg className="animate-spin -ml-1 mr-3 h-[14px] w-[14px] text-black" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
          </svg>
        </span>
    ) : (
        <>
            <aside
                className="flex flex-col justify-between h-screen w-[200px] px-4 py-5 bg-background border-r fixed"
            >
                <ul>
                    {menus ? menus.map((menu: any, i: any) => {
                        return(
                            <>
                                <AsideMenuItem key={`menu--${i}--${menu}`} menu={menu} menuTitle={menuTitle} />
                            </>
                        )
                    }) : ''}
                </ul>

                <footer className="flex flex-col items-center justify-center">
                    <small className="text-secondary">Visão do Cliente</small>
                    <small className="text-secondary">Versão 1.01.</small>
                </footer>
            </aside>
        </>

  );
}

export interface AsideMenuItemProps {
  menu: any;
  menuTitle?: string;
}

export function AsideMenuItem({
  menu,
  menuTitle
}: AsideMenuItemProps) {
  const [isOpened, setIsOpened] = useState(!!(menuTitle === menu.title || menu.subMenus?.find((sm: any) => sm.title === menuTitle)));

  const navigate = useNavigate();
  
  /*const Icon = (props: IconBaseProps) => {
      let icon: any = null;
      switch (menu.title) {
          case 'Dashboards':
              icon = MdDashboard;
              break;
          case 'Cadastros':
              icon = FaClipboardCheck;
              break;
          case 'Usuários':
              icon = FaUser;
              break;
      }
    if (! icon) return <></>;
    return createElement(icon, props);
  };*/

    const Icon = (props: IconBaseProps) => {
        if (! menu.icon) return <></>;
        return createElement(menu.icon, props);
    };

  function handleMainClick() {
    menu.path && navigate(menu.path);
    setIsOpened(!isOpened);
  }

  return (
    <li className={'text-sm'}>
      <div onClick={handleMainClick} className={Str.tw(
        'flex items-center justify-between px-1 py-2 cursor-pointer rounded-md',
        'transition hover:bg-light hover:text-primary',
        menuTitle === menu.title ? 'bg-light-dark font-bold' : '' 
      )}>
        <div className="flex items-center gap-2 break-normal">
          <Icon />
          {menu.title}
        </div>

        {!!menu.subMenus?.length && <FiChevronDown className={isOpened ? 'rotate-180' : ''} />}
      </div>

      <div className="pl-1" hidden={!isOpened}>
        {menu.subMenus?.map((subMenu: any, i:any) => (
          <div key={`subMenu--${subMenu.path}--${i}`} onClick={() => subMenu.path && navigate(subMenu.path)} className={Str.tw(
            'flex items-center justify-between px-2 py-2 cursor-pointer rounded-md text-[13px]',
            'transition hover:bg-light hover:text-primary',
            menuTitle === subMenu.title ? 'pointer-events-none bg-primary text-white font-bold' : ''
          )}>
            {subMenu.title}
          </div>
        ))}
      </div>
    </li>
  );
}