import React, { useState, useEffect } from 'react';
import useForm from '../../../hooks/useForm';
import { LoginAdminData } from '../../../types';
import Form from "../../../components/form/Form";
import Input from "../../../components/input/Input";
import Title from "../../../components/text/Title";
import ColoredButton from "../../../components/button/ColoredButton";
import authAdminApi from "../../../services/adminServices/authAdminApi";
import Storage from "../../../helpers/Storage";
import logoSuplen from '../../../assets/images/logo-suplen.png';
import logoTopMed from '../../../assets/images/ImageToStl.com_logo+topmed.png';
export default function LoginAdmin() {
    const [errorMessage, setErrorMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const { form, isFormInvalid, handleChange } = useForm<LoginAdminData>({
        validator: (data) => !data.password || !data.cpf
    });

    async function handleSubmit() {
        setErrorMessage('');
        const {data, isError} = await authAdminApi.login(form);
        if (isError) {
            setIsLoading(false);
            setErrorMessage(data.message);
            return;
        }
        Storage.setApiToken(data.token);
        Storage.setUserData({ usuario: data.usuario });
        setIsLoading(false);
        window.location.href = '/admin';
    }

    return (
        <div className={'bg-[#054da2]'}>
            <div className="flex items-center justify-center h-screen p-5">
                <div className="lg:shadow w-full w-max">
                    <Form
                        className="bg-slate-50 shadow lg:shadow-none p-9 grid place-items-center mx-auto lg:w-[26rem] rounded-lg"
                        onSubmit={() => {handleSubmit()}}
                    >
                        <div className={'flex gap-4'}>
                            <img src={logoSuplen} className="max-w-[125px]" />
                            <img src={logoTopMed} className="max-w-[125px]" />
                        </div>


                        {/*<Title size="2xl" color="primary" className="mb-2 mt-8">Bem vindo</Title>*/}
                        <p className="text-2xl  mb-5 text-primary font-bold mt-5">Portal Admin</p>
                        <p className="text-md text-secondary">Informe seus dados de acesso.</p>

                        {!!errorMessage?.length && <p className="mt-5 text-red-600">{errorMessage}</p>}
                        <Input
                            label={'CPF'}
                            large
                            mask={"CPF"}
                            className="my-5"
                            value={form?.cpf}
                            setValue={(v) => handleChange(v, 'cpf')}
                        />
                        {/*<Input
                            label={'Cliente'}
                            large
                            mask={"CNPJ"}
                            className="mb-5"
                            value={form?.cliente}
                            setValue={(v) => handleChange(v, 'cliente')}
                        />*/}

                        <Input
                            label={'Senha'}
                            large
                            inputType="password"
                            value={form?.password}
                            setValue={(v) => handleChange(v, 'password')}
                        />

                        <ColoredButton
                            className="mt-6 mb-4"
                            disabled={isFormInvalid}
                            loading={isLoading}
                            type="submit"
                        >
                            Entrar
                        </ColoredButton>

                        <a className="text-primary hover:text-primary-700 transition cursor-pointer" href={'/admin/recuperar/senha'}>
                            Esqueceu a senha?
                        </a>
                    </Form>
                </div>
            </div>
        </div>
    );

}