import React, {useEffect, useState} from 'react';
import { FiChevronDown } from 'react-icons/fi';

import Logo2Src from '../../../assets/images/stockgoLogo.png';
import Title from '../../../components/text/Title';
import Dropdown from '../../../components/dropdown/Dropdown';
import Storage from '../../../helpers/Storage';
import useAppContext from '../../../hooks/useAppContext';
import useModal from '../../../hooks/useModal';

import Modal from '../../../components/modal/Modal';
import Form from '../../../components/form/Form';
import Input from '../../../components/input/Input';
import Button from '../../../components/button/Button';

import Alert from '../../../helpers/Alert';
import {changePassword} from "../../../types";
/*import adminApi from '../../../services/adminApi';
import {changePassword} from "../../../types";
import menuApi from "../../../services/menuApi";
import {MdDashboard} from "react-icons/md";
import {FaClipboardCheck, FaUser} from "react-icons/fa";*/
import logoSupen from '../../../assets/images/logo-suplen.png';
import logoTopMed from '../../../assets/images/ImageToStl.com_logo+topmed.png';

export interface NavbarProps {
  pageTitle?: string;
}

export default function Navbar({
  pageTitle
}: NavbarProps) {
  const userData = Storage.getUserData();

  const appContext = useAppContext();

  const [dataPassword, setDataPassword] = useState<changePassword>(
    {
      atualPassword: '',
      novoPassword: '',
      repetirPassword: '',
    }
  );

  const { modalProps, open, close } = useModal();

  useEffect(() => {
    if (userData) {
      appContext.setUsuario(userData.usuario);
    }
  }, []);

  function handleLogout() {
    Storage.removeApiToken();
    Storage.removeUserData();
    window.location.href = '/admin/entrar';
  }

  async function changePassword(e: any) {
    /*e.preventDefault();
    if (dataPassword.novoPassword != dataPassword.repetirPassword) {
      Alert.error('Senhas São diferentes');
      return;
    }
    //salva a senha
    const {data, isError} = await adminApi.changePassword(dataPassword);
    if (isError) return Alert.error(data);
    Alert.success('Senha alterada com sucesso!');
    close();*/
  }

  return (
    <>
      <nav className="bg-background border-b flex items-center">

        <main className="mx-4 flex items-center justify-between flex-1">
          <Title size="lg">{pageTitle}</Title>
          <div className={'flex'}>
            <aside className="py-4 mr-3  flex">
              <img src={logoSupen} className="w-[100px] mr-5" />
              <img src={logoTopMed} className="w-[100px]" />
            </aside>
            <Dropdown
                trigger={<div className="flex items-center gap-2 hover:bg-primary hover:text-white mt-2 py-2 px-3 rounded-md">
              <span className="flex flex-col items-start ">
                <Title>Usuario</Title>
                <small className="text-secondary ">email@email.com</small>
              </span>

                  <FiChevronDown />
                </div>}
                items={[
                  { label: 'Alterar Senha', onClick: open },
                  { label: 'Sair', onClick: handleLogout  }
                ]}
            />
          </div>

        </main>
        <Modal size="lg" title="Alterar Senha" {...modalProps}>

          <Form onSubmit={changePassword}>
            <div className={'p-3'}>
              <Input
                label="Senha"
                inputType='password'
                value={dataPassword.atualPassword}
                setValue={(atualPassword) => setDataPassword({ ...dataPassword, atualPassword })}
              />

              <Input
                className={'mt-4'}
                label="Nova Senha"
                inputType='password'
                value={dataPassword.novoPassword}
                setValue={(novoPassword) => setDataPassword({ ...dataPassword, novoPassword })}
              />

              <Input
                className={'mt-4'}
                label="Repetir Senha"
                inputType='password'
                value={dataPassword.repetirPassword}
                setValue={(repetirPassword) => setDataPassword({ ...dataPassword, repetirPassword })}
              />
            </div>
            <div className={'p-3 flex '}>
              <Button type='submit' >
                Salvar
              </Button>
              <Button onClick={close} className={'ml-5'}>
                Cancelar
              </Button>
            </div>

          </Form>
        </Modal>
      </nav>
    </>
  );
}
