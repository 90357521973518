import { IconType } from 'react-icons/lib';
import {MdDashboard} from "react-icons/md";
import {FaClipboardCheck} from "react-icons/fa";
import { MdDocumentScanner } from "react-icons/md";
export interface Menu {
    title: string;
    path?: string;
    icon?: IconType;
    subMenus?: Menu[];
}

export default [
    {
        title: 'Dashboards',
        icon: MdDashboard,
        subMenus: [
            {
                title: 'Dashboard',
                path: '/admin'
            }
        ]
    },
    {
        title: 'Leituras',
        icon: MdDocumentScanner,
        subMenus: [
            {
                title: 'Leituras de inventário',
                path: '/admin/leituras'
            },
            {
                title: 'Leituras de apontamento',
                path: '/admin/leituras-apontamento'
            }
        ]
    },
    {
        title: 'Cadastros',
        icon: FaClipboardCheck,
        subMenus: [
            /*{
                title: 'Cadastro de clientes',
                path: '/admin/cadastros/clientes',
            },*/
            {
                title: 'Cadastro de usuários',
                path: '/admin/usuario',
            },
            {
                title: 'Cadastro de empresas',
                path: '/admin/empresa',
            }
        ]
    },
];