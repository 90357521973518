import PageContainer from "../../../../../components/container/PageContainer";
import PageCard from "../../../../../components/card/PageCard";
import {useNavigate, useParams} from 'react-router-dom';
import React, {useEffect, useState} from "react";
import Title from "../../../../../components/text/Title";
import IconButton from "../../../../../components/button/IconButton";
import {BiArrowBack} from 'react-icons/bi';
import Button from "../../../../../components/button/Button";
import {Usuario} from "../../../../../types";
import Input from "../../../../../components/input/Input";
import Checkbox from "../../../../../components/checkbox/Checkbox";
import Select from "../../../../../components/select/Select";
import clientesApi from "../../../../../services/adminServices/clientesApi";
import empresasApi from "../../../../../services/adminServices/empresasApi";
import SearchButton from "../../../../../components/button/SearchButton";
import usuariosAdminApi from "../../../../../services/adminServices/usuariosAdminApi";
import Alert from "../../../../../helpers/Alert";
import {Tab, Tabs} from "../../../../../components/tabs";

export default function FormularioUsuarios() {
    const params = useParams();
    const id = params.id;
    const navigate = useNavigate();
    const initialState = {
        id: '',
        nome: '',
        password: '',
        email: '',
        cpf: '',
        bloqueado: false,
        telefone: '',
        app_user: false,
        site_user: false,
        empresas: [],
        clientes: [],
    };
    const [state, setState] = useState<Usuario>(initialState);
    const [load, setLoad] = useState(false);
    const [loadTable, setLoadTable] = useState(true);
    const [loadPage, setLoadPage] = useState(true);
    const [checkAll, setCheckAll] = useState(false);
    const [clientesOptions, setClientesOptions]: any = useState([])
    const [clientes, setClientes]: any = useState([])
    const [empresasOptions, setEmpresasOptions] = useState([])
    const [pesquisa, setPesquisa] = useState('');
    const [dataClientes, setDataClientes]: any = useState([])

    async function loadData() {
        const [clientesData, empresasData] = await Promise.all([
            clientesApi.listAll(),
            empresasApi.getList()
        ]);
        setClientesOptions(clientesData.data);
        const empreOpt = empresasData.data.map((empresaOpt: any) => {
            return { label: empresaOpt.nome, key: empresaOpt.id, filial: empresaOpt.filial_protheus }
        })
        setEmpresasOptions(empreOpt);

        if (id){
            const {data, isError} = await usuariosAdminApi.one(id);
            let newArrayClientes: any[] = [];

            data.clientes.map((cliente: any) => {

                const empresaFilter = empresasData.data.find((empresa: any) => cliente.filial === empresa.filial_protheus);

                //verifica se existe dados no array
                if (newArrayClientes.length){
                    //se existir procura se existe a filial ja cadastrada
                    const findEmpresa = newArrayClientes.findIndex((arrayCliente: any) => arrayCliente.filial_protheus === cliente.filial);
                    //caso encontre adicione o cliente no array
                    if (findEmpresa !== -1){
                        newArrayClientes[findEmpresa]['clientes_id'].push(cliente.codigo+'_'+cliente.loja)
                    }else{
                        //se não adiciona um novo valor no array
                        const newData = {
                            nome_empresa: empresaFilter?.nome,
                            filial_protheus: empresaFilter?.filial_protheus,
                            clientes_id: [cliente.codigo+'_'+cliente.loja],
                            select_all: false,
                            clientes: [],
                            clientes_backup: [],
                            pesquisa: ''
                        };
                        newArrayClientes.push(newData);
                    }

                }else{
                    //caso não exista nenhuma informação no array, adiciona o primeiro valor
                    const newData = {
                        nome_empresa: empresaFilter?.nome,
                        filial_protheus: empresaFilter?.filial_protheus,
                        clientes_id: [cliente.codigo+'_'+cliente.loja],
                        select_all: false,
                        clientes_backup: [],
                        pesquisa: ''
                    };
                    newArrayClientes.push(newData);
                }
            });

            for (let i = 0; i < newArrayClientes.length; i++){
                const arrClient = newArrayClientes[i];
                const consultClientes = await clientesApi.getClientsFilial(arrClient?.filial_protheus);
                newArrayClientes[i]['clientes'] = consultClientes.data;
                newArrayClientes[i]['clientes_backup'] = consultClientes.data;
                newArrayClientes[i]['select_all'] = consultClientes.data.length === newArrayClientes[i]['clientes_id'].length;
            }

            setDataClientes(newArrayClientes);

            if (!isError){

                const newState = {
                    id: data.id,
                    nome: data.nome,
                    email: data.email,
                    cpf: data.cpf,
                    bloqueado: data.bloqueado,
                    telefone: data.telefone,
                    app_user: data.app_user,
                    site_user: data.site_user,
                    empresas: data.empresas.map((empresa: any) => empresa.id),
                    /*clientes: data.clientes.map((clientes: any) => clientes.cliente_cnpj),*/
                };
                setState(newState);
            }
        }

        setLoad(false);
        setLoadPage(false);
        setLoadTable(false);
    }

    useEffect(() => {
        if(id){
            setLoad(true);
        }
        loadData();
    }, []);

    const handleSubmit = async () => {

        setLoad(true);
        let clientFinal: any[] = []
        dataClientes.map((dataCliente: any) => {
            dataCliente.clientes_id.map((id: string) => {
                const lojaFilial = id.split('_');
                clientFinal.push(
                    {
                        codigo: lojaFilial[0],
                        loja: lojaFilial[1],
                        filial: dataCliente.filial_protheus
                    }
                )
            })
        });
        state.clientes = clientFinal;

        const {data, isError} = id ? await usuariosAdminApi.update(id, state) : await usuariosAdminApi.create(state);
        setLoad(false);
        if (isError){
            if (typeof data === 'object') {
                Object.keys(data).forEach(function(key, index) {
                    Alert.error(data[key][0]);
                });
                return;
            }

            return Alert.error(data.message? data.message : data);
        }

        Alert.success('Cliente salvo com sucesso');
        navigate('/admin/usuario');

    }
    async function handleDelete() {
        console.log('aki');
    }
    const handleChange = (value: any, input: string) => {
        setState({...state, [input]: value});
    };
    const handleSelectEmpresas = async (value: any, input: string) => {
        let r3 = state.empresas.filter( (a: any) => !value.includes( a ) );
        if (!r3.length){
            setState({...state, [input]: value});
            const empresaFind: any = empresasOptions.find((empresa: any) => empresa.key === value[value.length - 1]);
            const {data, isError} = await clientesApi.getClientsFilial(empresaFind?.filial);

            const newData = {
                nome_empresa: empresaFind?.label,
                filial_protheus: empresaFind?.filial,
                clientes_id: [],
                select_all: false,
                clientes: isError ? [] : data,
                clientes_backup: isError ? [] : data,
                pesquisa: ''
            };
            const dt = [...dataClientes];
            dt.push(newData)
            setDataClientes(dt);
        }else{
            const empresaFind: any = empresasOptions.find((empresa: any) => empresa.key === r3[0]);
            setDataClientes(dataClientes.filter((dtCliente: any) => dtCliente.nome_empresa !== empresaFind.label))
        }
    };
    function onCkeckClientes(l: any, dataClientIndex: number, key: number){
        let newDataCliente = [...dataClientes];
        if (newDataCliente[dataClientIndex].clientes_id.includes(l.codigo+'_'+l.loja)){
            newDataCliente[dataClientIndex].clientes_id = newDataCliente[dataClientIndex].clientes_id.filter((client: any) => client !== l.codigo+'_'+l.loja)
            setDataClientes(newDataCliente);
        }else{
            newDataCliente[dataClientIndex].clientes_id.push(l.codigo+'_'+l.loja);
            setDataClientes(newDataCliente);
        }

        /*const clientesSelected = [...state.clientes];
        if (state.clientes.includes(l.codigo+l.loja)){
            const filterCliente = clientesSelected.filter((client: any) => client !== l.codigo+l.loja);
            setState({...state, ['clientes']: filterCliente});
        }else{
            clientesSelected.push(l.codigo+l.loja);
            setState({...state, ['clientes']: clientesSelected});
        }*/
    }
    function onCheckAll(e: any, index: number) {
        let newDataCliente = [...dataClientes];
        if (e){
            newDataCliente[index].clientes_id = newDataCliente[index].clientes.map((cliente: any) => cliente.codigo +'_'+ cliente.loja);
            newDataCliente[index].select_all = true;
            setDataClientes(newDataCliente);
        }else{
            newDataCliente[index].clientes_id = [];
            newDataCliente[index].select_all = false;
            setDataClientes(newDataCliente);
        }
        /*if (e){
            const newClient = clientesOptions.map((clientes: any) => clientes.codigo+clientes.loja);
            setState({...state, ['clientes']: newClient});
        }else{
            setState({...state, ['clientes']: []});
        }
        setCheckAll(e);*/
    }

    function buscaClientesArray(index: number){


        let newDataCliente = [...dataClientes]
        setLoadTable(true);
        if (newDataCliente[index]['pesquisa'].length < 2) {
            newDataCliente[index]['clientes'] = newDataCliente[index]['clientes_backup'];
        }else{
            newDataCliente[index]['clientes'] = newDataCliente[index]['clientes_backup'].filter((cliente: any) =>
                cliente.razaosocial.toLowerCase().includes(newDataCliente[index]['pesquisa'].toLowerCase())
            );
        }
        setDataClientes(newDataCliente);
        setLoadTable(false);
        /*setLoadTable(true);
        if (pesquisa.length < 2) {
            setClientesOptions(clientes);
        }else{
            const stateClientes = [...clientesOptions];
            const newStateClientes = stateClientes.filter((cliente: any) =>
                cliente.razaosocial.toLowerCase().includes(pesquisa.toLowerCase())
            );
            setClientesOptions(newStateClientes);
        }
        setLoadTable(false);*/
    }

    function setPesquisaData(value: any, index: number) {
        let newDataClientes = [...dataClientes];
        newDataClientes[index]['pesquisa'] = value;
        setDataClientes(newDataClientes);
    }

    return(
        <PageContainer menuTitle="Cadastro de Usuário">
            <PageCard
                header={
                    <div className="flex items-center justify-between">
                        <Title color="primary">Cadastro de Usuário</Title>
                        <IconButton title="Voltar" icon={BiArrowBack} onClick={() => navigate('/admin/usuario')} />
                    </div>
                }
                footer={
                    <div className="flex items-center gap-5">
                        <Button loading={load} onClick={handleSubmit}>
                            Salvar
                        </Button>
                        {state.id && <Button loading={load} onClick={handleDelete}>Excluir</Button>}
                        {/*{state.id && <Button loading={load} onClick={() => {window.location.href = '/usuarios/cadastro';}}>Novo</Button>}*/}
                    </div>
                }
            >
                {
                    loadPage ?
                        <span className="flex items-center justify-center h-7">
                          <svg className="animate-spin -ml-1 mr-3 h-[14px] w-[14px] text-black" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                          </svg>
                        </span>
                        :
                        <>
                            <div className="grid grid-cols-12 gap-5 pb-7">
                                <Input
                                    label="Nome"
                                    className="sm:col-span-6"
                                    value={state.nome}
                                    setValue={v => handleChange(v, 'nome')}
                                />
                                <Input
                                    label="E-mail"
                                    className="sm:col-span-6"
                                    value={state.email}
                                    setValue={v => handleChange(v, 'email')}
                                />
                                <Input
                                    label="CPF"
                                    inputType="CPF"
                                    className="col-span-2"
                                    value={state.cpf}
                                    setValue={(v) => handleChange(v, 'cpf')}
                                />
                                <div className="col-span-2">
                                    <Checkbox
                                        className={'sm:mt-[2rem]'}
                                        name={'app_user'}
                                        label={'Usuario app'}
                                        value={state.app_user}
                                        setValueTarget={(v) => handleChange(!state.app_user, 'app_user')}
                                    />
                                </div>
                                <div className="col-span-2">
                                    <Checkbox
                                        className={'sm:mt-[2rem]'}
                                        name={'site_user'}
                                        label={'Usuario site'}
                                        value={state.site_user}
                                        setValueTarget={() => handleChange(!state.site_user, 'site_user')}
                                    />
                                </div>
                                <div className="col-span-2">
                                    <Checkbox
                                        className={'sm:mt-[2rem]'}
                                        name={'site_user'}
                                        label={'Bloqueado'}
                                        value={state.bloqueado}
                                        setValueTarget={() => handleChange(!state.bloqueado, 'bloqueado')}
                                    />
                                </div>

                                <Input
                                    label="Telefone"
                                    mask={"cellphone"}
                                    className="col-span-4"
                                    value={state.telefone}
                                    setValue={(v) => handleChange(v, 'telefone')}
                                />
                                <Select
                                    multiple={true}
                                    label="Empresas"
                                    className="col-span-4 z-10"
                                    value={state.empresas}
                                    setValue={v => handleSelectEmpresas(v, 'empresas')}
                                    items={empresasOptions}
                                />

                            </div>
                            {/*{
                                state.app_user ?
                                    loadTable ? <>Carregando</> :
                                        <>
                                            <div className={'text-[18px] font-semibold'}>
                                                Clientes
                                            </div>
                                            <div className="border rounded-md mt-2 p-5 h-[calc(60vh_-_100px)] overflow-auto">
                                            <div className={'flex sticky top-0 bg-white'}>
                                                <Input
                                                    hideLabel
                                                    placeholder={'Pesquisa'}
                                                    className={'w-[340px]'}
                                                    value={pesquisa}
                                                    setValue={e => setPesquisa(e)}
                                                />
                                                <SearchButton className={'ml-3'} onClick={buscaClientesArray} />
                                            </div>
                                            <div className={'flex'}>

                                                <div className="mr-[1rem] p-2">
                                                    <Checkbox
                                                        setValueTarget={(e) => onCheckAll(!checkAll)}
                                                        name={`selecioneTodos`}
                                                        value={checkAll}
                                                        label={`Selecionar todos`}
                                                    />
                                                </div>
                                            </div>

                                            {
                                                clientesOptions.length ? clientesOptions.map((cliente: any, key: number) => {
                                                    return(
                                                        <div className={'flex'}>

                                                            <div className="mr-[1rem] p-2">
                                                                <Checkbox
                                                                    setValueTarget={() => onCkeckClientes(cliente, key)}
                                                                    name={`${cliente.razaosocial}-${cliente.codigo}`}
                                                                    value={state.clientes.includes(cliente.codigo+cliente.loja)}
                                                                    label={`Razão social: ${cliente.razaosocial} ${ cliente.cnpj ? '| CNPJ:'+cliente.cnpj.replace(/^(\\d{2})(\\d{3})(\\d{3})(\\d{4})(\\d{2})/, "$1 $2 $3/$4-$5") : ''} | Codigo: ${cliente.codigo}`}
                                                                />
                                                            </div>
                                                        </div>
                                                    );
                                                }) : ''
                                            }
                                        </div>
                                        </>
                                    :
                                    ''
                            }*/}
                            {
                                dataClientes.length ?

                                    <div>
                                        <div className={'text-[18px] font-semibold'}>
                                            Clientes
                                        </div>
                                        <Tabs>
                                            {
                                                dataClientes.map((cliente: any, dalaClientIndex: number) => {
                                                    return(
                                                        <Tab label={cliente.nome_empresa}>
                                                            <div className="py-4">
                                                                <h2 className="text-lg font-medium mb-2">{cliente.nome_empresa}</h2>
                                                                <div className="border rounded-md mt-2 px-5 h-[calc(60vh_-_100px)] overflow-auto">
                                                                    <div className={'flex sticky top-0 bg-white h-[65px] items-center '}>
                                                                        <Input
                                                                            hideLabel
                                                                            placeholder={'Pesquisa'}
                                                                            className={'w-[340px]'}
                                                                            value={cliente.pesquisa}
                                                                            setValue={e => setPesquisaData(e, dalaClientIndex)}
                                                                        />
                                                                        <SearchButton className={'ml-3'} onClick={(e: any) => buscaClientesArray(dalaClientIndex)} />
                                                                    </div>
                                                                    <div className={'flex'}>

                                                                        <div className="mr-[1rem] p-2">
                                                                            <Checkbox
                                                                                setValueTarget={(e) => onCheckAll(!cliente.select_all, dalaClientIndex)}
                                                                                name={`selecioneTodos`}
                                                                                value={cliente.select_all}
                                                                                label={`Selecionar todos`}
                                                                            />
                                                                        </div>
                                                                    </div>

                                                                    {
                                                                        cliente.clientes ? cliente.clientes.map((clienteDt: any, index: number) => {
                                                                            return(
                                                                                <div className={'flex'}>

                                                                                    <div className="mr-[1rem] p-2">
                                                                                        <Checkbox
                                                                                            setValueTarget={() => onCkeckClientes(clienteDt, dalaClientIndex, index)}
                                                                                            name={`${clienteDt.razaosocial}-${clienteDt.codigo}`}
                                                                                            value={cliente.clientes_id.includes(clienteDt.codigo+'_'+clienteDt.loja)}
                                                                                            label={`Razão social: ${clienteDt.razaosocial} ${ clienteDt.cnpj ? '| CNPJ:'+clienteDt.cnpj.replace(/^(\\d{2})(\\d{3})(\\d{3})(\\d{4})(\\d{2})/, "$1 $2 $3/$4-$5") : ''} | Codigo: ${clienteDt.codigo}`}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        }) : ''
                                                                    }

                                                                </div>
                                                            </div>
                                                        </Tab>
                                                    )
                                                })
                                            }
                                            {/*<Tab label="Tab 1">
                                                <div className="py-4">
                                                    <h2 className="text-lg font-medium mb-2">Tab 1 Content</h2>
                                                    <p className="text-gray-700">
                                                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia, molestiae
                                                        quas vel sint commodi repudiandae consequuntur voluptatum laborum numquam blanditiis
                                                        harum quisquam eius sed odit fugiat iusto fuga praesentium optio, eaque rerum!
                                                        Provident similique accusantium nemo autem. Veritatis obcaecati tenetur iure eius
                                                        earum ut molestias architecto voluptate aliquam nihil, eveniet aliquid culpa officia
                                                        aut! Impedit sit sunt quaerat, odit, tenetur error, harum nesciunt ipsum debitis quas
                                                        aliquid. Reprehenderit, quia. Quo neque error repudiandae fuga? Ipsa laudantium
                                                        molestias eos sapiente officiis modi at sunt excepturi expedita sint? Sed quibusdam
                                                        recusandae alias error harum maxime adipisci amet laborum.
                                                    </p>
                                                </div>
                                            </Tab>
                                            <Tab label="Tab 2">
                                                <div className="py-4">
                                                    <h2 className="text-lg font-medium mb-2">Tab 2 Content</h2>
                                                    <p className="text-gray-700">
                                                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia, molestiae
                                                        quas vel sint commodi repudiandae consequuntur voluptatum laborum numquam blanditiis
                                                        harum quisquam eius sed odit fugiat iusto fuga praesentium optio, eaque rerum!
                                                        Provident similique accusantium nemo autem. Veritatis obcaecati tenetur iure eius
                                                        earum ut molestias architecto voluptate aliquam nihil, eveniet aliquid culpa officia
                                                        aut! Impedit sit sunt quaerat, odit, tenetur error, harum nesciunt ipsum debitis quas
                                                        aliquid. Reprehenderit, quia. Quo neque error repudiandae fuga? Ipsa laudantium
                                                        molestias eos sapiente officiis modi at sunt excepturi expedita sint? Sed quibusdam
                                                        recusandae alias error harum maxime adipisci amet laborum.
                                                    </p>
                                                </div>
                                            </Tab>
                                            <Tab label="Tab 3">
                                                <div className="py-4">
                                                    <h2 className="text-lg font-medium mb-2">Tab 3 Content</h2>
                                                    <p className="text-gray-700">
                                                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia, molestiae
                                                        quas vel sint commodi repudiandae consequuntur voluptatum laborum numquam blanditiis
                                                        harum quisquam eius sed odit fugiat iusto fuga praesentium optio, eaque rerum!
                                                        Provident similique accusantium nemo autem. Veritatis obcaecati tenetur iure eius
                                                        earum ut molestias architecto voluptate aliquam nihil, eveniet aliquid culpa officia
                                                        aut! Impedit sit sunt quaerat, odit, tenetur error, harum nesciunt ipsum debitis quas
                                                        aliquid. Reprehenderit, quia. Quo neque error repudiandae fuga? Ipsa laudantium
                                                        molestias eos sapiente officiis modi at sunt excepturi expedita sint? Sed quibusdam
                                                        recusandae alias error harum maxime adipisci amet laborum.
                                                    </p>
                                                </div>
                                            </Tab>*/}
                                        </Tabs>
                                    </div>
                                    :
                                    ''
                            }



                        </>
                }


            </PageCard>
        </PageContainer>
    )
}