import React from 'react';
import { AppRoute } from "../index";
import Login from "../../pages/Admin/Login";
import DashboardAdmin from "../../pages/Admin/Dashboards/Dashboard";
import Usuarios from "../../pages/Admin/Cadastros/Usuarios";
import Clientes from "../../pages/Admin/Cadastros/Clientes";
import RecuperarSenha from "../../pages/Admin/RecuperarSenha";
import FormularioUsuarios from "../../pages/Admin/Cadastros/Usuarios/Form";
import Leituras from "../../pages/Admin/Leituras/Leituras";
import LeiturasApontamento from "../../pages/Admin/Leituras/LeiturasApontamento";
import Empresas from "../../pages/Admin/Cadastros/Empresas";
import FormularioEmpresas from "../../pages/Admin/Cadastros/Empresas/Form";

const appRoutesAdmin: AppRoute[] =  [

    {
        path: '/admin/entrar',
        requiresAuth: false,
        element: <Login />
    },
    {
        path: '/admin/recuperar/senha',
        requiresAuth: false,
        element: <RecuperarSenha />
    },
    {
        path: '/admin',
        requiresAuth: true,
        element: <DashboardAdmin />
    },
    {
        path: '/admin/cadastros/clientes',
        requiresAuth: true,
        element: <Clientes />
    },
    {
        path: '/admin/usuario',
        requiresAuth: true,
        element: <Usuarios />
    },

    {
        path: '/admin/usuario/cadastro',
        requiresAuth: true,
        element: <FormularioUsuarios />
    },
    {
        path: '/admin/usuario/cadastro/:id',
        requiresAuth: true,
        element: <FormularioUsuarios />
    },
    {
        path: '/admin/empresa/cadastro',
        requiresAuth: true,
        element: <FormularioEmpresas />
    },
    {
        path: '/admin/empresa/cadastro/:id',
        requiresAuth: true,
        element: <FormularioEmpresas />
    },
    {
        path: '/admin/empresa',
        requiresAuth: true,
        element: <Empresas />
    },
    {
        path: '/admin/leituras',
        requiresAuth: true,
        element: <Leituras />
    },
    {
        path: '/admin/leituras-apontamento',
        requiresAuth: true,
        element: <LeiturasApontamento />
    },
    {
        path: '/*',
        requiresAuth: false,
        element: <h1>404</h1>
    }
];

export default appRoutesAdmin;