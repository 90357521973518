import PageContainer from "../../../../components/container/PageContainer";
import ListPageLayout from "../../../../components/layout/ListPageLayout";
import usuariosAdminApi from "../../../../services/adminServices/usuariosAdminApi";

export default function Usuarios() {
    return <ListPageLayout
        menuTitle="Cadastro de usuários"
        searchPlaceholder="Buscar usuários"
        creationEndpoint="/admin/usuario/cadastro"
        creationLabel="Cadastrar Usuário"
        loader={usuariosAdminApi.all}
        columns={[
            { title: 'ID', field: 'id' },
            { title: 'Nome', field: 'nome' },
            { title: 'E-mail', field: 'email' },
            { title: 'Telefone', field: 'telefone', columnType: 'phone' },
            { title: 'Status', field: 'bloqueado', columnType: 'reversedStatus' },
        ]}
    />;
    /*return(
        <PageContainer menuTitle="Usuários">
        </PageContainer>
    )*/
}